<template>
<a-card :bordered="false" class="dashboard-bar-line header-solid">
    <template #title>
      <h6>Price comparison Table</h6>
      <p>Historical price changes in latest 10 days</p>
    </template>
  <a-table :columns="columns" :data-source="data">
  </a-table>
  </a-card>
</template>

<script>

import {getpricedata} from '@/api/product';

const columns = [
  {
    title: 'Competitor',
    dataIndex: 'site',
  },
  {
    title: 'Date',
    dataIndex: 'timestamp',
  },
  {
    title: 'Price',
    dataIndex: 'price',
  },
];

const data = []

export default {
  data() {
    return {
      data,
      columns,
    };
  },
  props: ['productid'],
  mounted() {
    getpricedata(this.productid).then(data=>{
        var ourdata = []
       for (var i = 0; i < data.length; i++) {
           var item = data[i];
           for (var j=0;j<item.item.length;j++){
              var d = new Date(item.item[j]['timestamp']);
              item.item[j]['timestamp'] = d.toString().substring(0,21)
              ourdata.push(item.item[j]);
           }

}  
      this.data = ourdata
    })
  }

};
</script>