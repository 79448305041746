<template>
  <a-card
    :bordered="false"
    class="header-solid h-full"
    :bodyStyle="{ padding: 0 }"
  >
    <a-card :bordered="false" class="dashboard-bar-chart">
      <div class="card-title">
        <a-col :span="24" :md="12">
        <h5>{{ productname2 }}</h5>
        </a-col>
        <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">

        <!-- <a-button icon="plus" size="small" shape="round"  type="primary"  @click="addurl()"
          >Add competitor URL</a-button> -->
         <!-- <a-button class="ml-20" size="small" @click="aiproduct" type="primary" shape="round" icon="google">AI</a-button> -->
        </a-col>
      </div>
    </a-card>
    <a-tabs default-active-key="goal">
      <a-tab-pane key="goal" tab="Goals">
        <a-table :columns="goalcolumn" :data-source="goals" :pagination="true" v-show="goals.length">
          <template slot="editBtn" slot-scope="row">
            <a-button shape="circle" @click="editgoal(row)" icon="edit">
            </a-button>
          </template>
          <template slot="delBtn" slot-scope="row">
            <a-button shape="circle" @click="delgoal(row)" icon="delete">
            </a-button>
          </template>
          <template slot="navBtn" slot-scope="row">
            <a-button shape="circle" @click="navgoal(row)" icon="right">
            </a-button>
          </template>
        </a-table>
        <br />
        <a-button icon="plus" type="primary" shape="round"   @click="addgoal()"
          >Add goal</a-button>
      </a-tab-pane>
      <a-tab-pane key="priceline" v-bind:tab="'Price history (' + pricelasttime + ')'" :disabled="dis1">
        <a-card :bordered="false" class="dashboard-bar-chart">
          <div class="card-title">
            <h6>Price comparison</h6>
            <p>Historical price changes during a time</p>
          </div>
          <chart-line
            :height="500"
            :data="chartdata"
            :xaxis="xlabel1"
            :yaxis="ylabel1"
          ></chart-line>
        </a-card>
      </a-tab-pane>
      <a-tab-pane key="table1" v-bind:tab="'Price table (' + pricelasttime + ')'" :disabled="dis1">
        <a-card :bordered="false" class="dashboard-bar-chart">
          <a-row :gutter="24" type="flex" align="stretch">
            <a-col :span="24" :lg="24" class="mb-24">
              <!-- Sales Overview Card -->
              <CardPriceComparisonTable
                :productid="productid"
              ></CardPriceComparisonTable>
              <!-- / Sales Overview Card -->
            </a-col>
          </a-row>
        </a-card>
      </a-tab-pane>
      <a-tab-pane key="table2" v-bind:tab="'Sales table (' + saleslasttime + ')'" :disabled="dis2">
        <a-card :bordered="false" class="dashboard-bar-chart">
          <a-row :gutter="24" type="flex" align="stretch">
            <a-col :span="24" :lg="24" class="mb-24">
              
              <CardSalesRevenueTable
                :productid="productid"
              ></CardSalesRevenueTable>
             
            </a-col>
          </a-row>
        </a-card>
      </a-tab-pane>
      <a-tab-pane key="salesline" v-bind:tab="'Sales history (' + saleslasttime + ')'" :disabled="dis2">
        <a-card :bordered="false" class="dashboard-bar-chart">
          <div class="card-title">
            <h6>Sales revenue</h6>
            <p>pos data revenue for the product</p>
          </div>
          <chart-line
            :height="500"
            :data="salesdata"
            :enablelegend="0"
            :xaxis="xlabel2"
            :yaxis="ylabel2"
          ></chart-line>
        </a-card>
      </a-tab-pane>
      <a-tab-pane key="corr" v-bind:tab="'Correlation (' + saleslasttime + ')'" :disabled="dis3">
        <a-card :bordered="false" class="dashboard-bar-chart">
          <div class="card-title">
            <h6>Most correlated products sold with this product</h6>
            <p>
              When customer buys this product, how many others are sold together
            </p>
          </div>
          <chart-bar
            :height="400"
            :data="barChartData1"
            :xaxis="xlabel3"
            :yaxis="ylabel3"
          ></chart-bar>
        </a-card>
      </a-tab-pane>
      <a-tab-pane key="urls" v-bind:tab="'URLs ( ' + urltime + ')'">
        <a-table :columns="urlcolumn" :data-source="urldata" :pagination="true" v-show="urldata.length">
          <template slot="delBtn" slot-scope="row">
            <a-button shape="circle" @click="delurl(row)" icon="delete">
            </a-button>
          </template>
        </a-table>
      </a-tab-pane>

      <!-- <a-tab-pane key="simulation" tab="Price simulation">
        <a-card :bordered="false" class="dashboard-bar-chart">
          <div class="card-title">
            <h6>Price change simulation</h6>
            <p>change your price here</p>
          </div>
          <a-form :form="form" @submit="handleSubmit">
            <a-form-item label="Price simulation">
              <a-input v-decorator="['price']" />
            </a-form-item>
            <a-button type="primary" html-type="submit"> Submit </a-button>
          </a-form>
        </a-card>
      </a-tab-pane> -->
    </a-tabs>
  </a-card>
</template>
<script>
import {
  getitem,
  getpricedata,
  getcorrdata,
  getsalesforproduct,
  simulate,
  deleteurl,
  deleteproductgoal,
} from "@/api/product";
import ChartLine from "@/components/Charts/ChartLine";
import ChartBar from "@/components/Charts/ChartBar";
import { getproductgoals } from "@/api/goal";
import CardPriceComparisonTable from "../components/Cards/CardPriceComparisonTable";
import CardSalesRevenueTable from "../components/Cards/CardSalesRevenueTable";

export default {
  components: {
    ChartLine,
    ChartBar,
    CardPriceComparisonTable,
    CardSalesRevenueTable,
  },
  props: {
    productid: String,
    productname: String,
  },
  data() {
    return {
      product: {},
      urldata: [],
      urlcolumn: [
        { title: "Competitors' URLs", dataIndex: "item.url" },
        {
          title: "",
          dataIndex: "item.id",
          scopedSlots: { customRender: "delBtn" },
          width: 40,
        },
      ],
      goalcolumn: [
        { title: "Product goal list", dataIndex: "name" },
        {
          title: "",
          dataIndex: "goalid",
          key: "goalid2",
          scopedSlots: { customRender: "editBtn" },
          width: 40,
        },
        {
          title: "",
          dataIndex: "goalid",
          scopedSlots: { customRender: "delBtn" },
          width: 40,
        },
        {
          title: "",
          dataIndex: "goalid",
          key: "goalid3",
          scopedSlots: { customRender: "navBtn" },
          width: 40,
        },
      ],
      chartdata: {},
      legends: [],
      barChartData: {},
      barChartData1: {},
      salesdata: {},
      productname2: "",
      goals: [],
      xlabel1: "date (interval one day)",
      ylabel1: "price in SEK",
      xlabel2: "date (interval one day)",
      ylabel2: "number of sales",
      xlabel3: "correlated products",
      ylabel3: "cross-selling possibility",
      form: this.$form.createForm(this, { name: "simulation of pricechange" }),
      tabledatasales: {},
      dis1: true,
      dis2: true,
      dis3: true,
      pricelasttime: "",
      saleslasttime: "",
      urltime: "",

    };
  },
  mounted() {
    getitem(this.productid).then((data) => {
      this.product = data;
      this.productname2 = this.product.name;
      this.urltime = this.product.urltime.substring(2);
      this.urldata = [];
      if (data.urls) {
        // function: product-11
        for (var i = 0; i < data.urls.length; i++)
          this.urldata.push({ item: data.urls[i] });
      }

      getproductgoals(this.productid).then((data) => {
        this.goals = data;
      });
      // function: product-17
      getcorrdata(this.productid).then((result) => {
        if (result.length !== 0){this.dis3=false};
        var datacount = [];
        var labels = [];
        var percent = [];
        for (var i = 0; i < result.length; i++) {
          var item = result[i];

          datacount.push(item.count);
          labels.push(item.ID2);
          percent.push(item.percentage);
        }

        var datasets = [
          {
            label: "Sales",
            backgroundColor: ["#9A89FF", "#00D69E"],
            borderWidth: 0,
            borderSkipped: false,
            borderRadius: 6,
            data: datacount,
            maxBarThickness: 20,
          },
        ];
        var datasets1 = [
          {
            label: "Percentage",
            backgroundColor: ["#9A89FF", "#00D69E"],
            borderWidth: 0,
            borderSkipped: false,
            borderRadius: 6,
            data: percent,
            maxBarThickness: 20,
          },
        ];

        this.barChartData = {
          labels: labels,
          datasets: datasets,
        };

        this.barChartData1 = {
          labels: labels,
          datasets: datasets1,
        };
      });
      // function: product-15
      getpricedata(this.productid).then((result) => {
        if (result.length !== 0){this.dis1=false};
        this.tabledata2 = result;
        var datasets = [];
        var labels = [];
        var ourcolors = ["#00D69E", "#9A89FF", "#FFB636"]; //this is designed for three competitors secnario, if the number of competitors changes, color config also needs to be changed
        for (var i = 0; i < result.length; i++) {
          var item = result[i];
          var rawdata = [];
          for (var j = 0; j < item.item.length; j++) {
            rawdata.push(item.item[j].price);
            if (i == 0) {
              labels.push(item.item[j].timestamp?.substring(0, 10));
            }
          }
          var dataitem = {
            label: item.name,
            tension: 0.4,
            borderWidth: 3,
            pointRadius: 2,
            borderColor: ourcolors[i],
            data: rawdata,
            maxBarThickness: 6,
          };
          datasets.push(dataitem);
        }
        this.pricelasttime = labels.slice(-1)[0].substring(2);
        this.chartdata = {
          labels: labels,
          datasets: datasets,
        };
      });
      // function: product-16
      getsalesforproduct(this.productid).then((salesresult) => {
        if (salesresult.length !== 0){this.dis2=false};
        var datasets = [];
        var labels = [];
        var rawdata = [];
        for (var i = 0; i < salesresult.length; i++) {
          var item = salesresult[i];
          // var itemColor = this.getRandomColor();

          labels.push(item.key_as_string.substring(0, 10));
          rawdata.push(item.doc_count);
        }
        var dataitem = {
          label: "sales revenue",
          tension: 0.4,
          borderWidth: 3,
          pointRadius: 3,
          borderColor: "#00D69E",
          data: rawdata,
          maxBarThickness: 6,
        };
        datasets.push(dataitem);
        this.saleslasttime = labels.slice(-1)[0]?labels.slice(-1)[0].substring(2):'';
        this.salesdata = {
          labels: labels,
          datasets: datasets,
        };
      });
    });
  },
  methods: {
    addurl() {
      // function: product-12
      this.$router.push({
        path: "/addurl",
        query: { productid: this.productid },
      });
    },
    delurl(id) {
      //function: Product-10
      deleteurl({ url: id, id: this.productid }).then((data) => {
        for (var i = 0; i < this.urldata.length; i++) {
          if (this.urldata[i].item.id == id) {
            this.urldata.splice(i, 1);
            break;
          }
        }
      });
    },
    aiproduct() {
      this.$router.push({
        path: "productaisuggestion",
        query: { productid: this.productid },
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          simulate(values.price, this.productid, this.product.name);
        }
      });
    },
    getRandomColor() {
      var letters = "0123456789ABCDEF".split("");
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    addgoal() {
      this.$router.push({
        path: "/goaledit",
        query: { goalid: 0, categoryid: this.productid, goaltype: 2 },
      });
    },
    delgoal(id) {
      deleteproductgoal({
        productid: this.product.productKey,
        goalid: id,
      }).then((data) => {
        for (var i = 0; i < this.goals.length; i++)
          if (this.goals[i].goalid == id) this.goals.splice(i, 1);
      });
    },
    editgoal(id) {
      this.$router.push({
        path: "/goaledit",
        query: { goalid: id, categoryid: this.product.productKey, type: 2 },
      });
    },
    navgoal(id) {
      this.$router.push({
        path: "/goaldetail",
        query: { goalid: id, categoryid: this.product.productKey, type: 2 },
      });
    },
  },
};
</script>