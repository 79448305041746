<template>
<a-card :bordered="false" class="dashboard-bar-line header-solid">
    <template #title>
      <h6>Sales Revenue Table</h6>
      <p>POS data revenue for the product in latest 10 days</p>
    </template>
  <a-table :columns="columns" :data-source="data">
  </a-table>
  </a-card>
</template>

<script>

import {getsalesforproduct} from '@/api/product';

const columns = [
  {
    title: 'Date',
    dataIndex: 'key_as_string',
  },
  {
    title: 'Sales amount',
    dataIndex: 'doc_count',
  },
];
const data = []

export default {
  data() {
    return {
      columns,
      data
    };
  },
  props: ['productid'],

  mounted() {
    getsalesforproduct(this.productid).then(data=>{
      for (var i = 0; i < data.length; i++){
        console.log(data[i])
        var d = new Date(data[i]['key_as_string']);
        data[i]['key_as_string'] = d.toString().substring(0,21);
      }
      this.data = data
    })
  }

};
</script>